.avatar {
  border: 3px #37caec solid;
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

.avatar-bg-success {
  background: #b9fb1a;
  border-radius: 50%;
}

.avatar-success {
  opacity: 0.5;
}
